<template>
  <div>
    <div class="rn_my_tit_1">
      {{ myInfo.reviewer_nick }}님의 페이지<router-link
        to=""
        @click.native="checkAuth"
        ><img src="@/assets/images/sub/my_edit_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_1">
      <div class="left">
        <img :src="myImg" @error="replaceImg" alt="" />
      </div>
      <div class="right">
        <ul>
          <li @click="$router.push('/mypage/reward_ramm_point_list')">
            내 리뷰 보상 포인트<router-link to=""
              ><img src="@/assets/images/sub/my_right_ico_1.png" alt=""
            /></router-link>
            <p>
              총
              {{
                won(
                  parseInt(myInfo.ramm_epoint.review_point) +
                    parseInt(myInfo.ramm_epoint.point)
                )
              }}P
            </p>
          </li>
          <li @click="$router.push('/review/review_statistics')">
            내 리뷰 통계<router-link to=""
              ><img src="@/assets/images/sub/my_right_ico_1.png" alt=""
            /></router-link>
          </li>
          <li @click="$router.push('/review/review_current_state')">
            실시간 리뷰 현황<router-link to=""
              ><img src="@/assets/images/sub/my_right_ico_1.png" alt=""
            /></router-link>
          </li>
          <li
            @click="
              $router.push(
                `/review/reviewer_goods/${myInfo.member_seq}?from=myinfo`
              )
            "
          >
            내 [리뷰어 홈]<router-link to=""
              ><img src="@/assets/images/sub/my_right_ico_1.png" alt=""
            /></router-link>
          </li>
        </ul>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
  </div>

  <!---->
</template>

<script>
import check from "@/mixin/checkAuth.js";
export default {
  props: {
    myInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [check],
  computed: {
    myImg() {
      return this.myInfo.reviewer_img
        ? `${process.env.VUE_APP_API_URL}${this.myInfo.reviewer_img}`
        : "";
    },
  },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    won(str) {
      return this.$won(str);
    },
  },
};
</script>

<style></style>
