<template>
  <div>
    <div class="sect_box my_num_4">
      <div class="list" @click="createChannel('reviewer')">
        <div class="left">리뷰어 채널 등록 신청하기(실명인증 필수)</div>
        <div class="right">
          <router-link to=""
            ><img src="@/assets/images/sub/my_move_ico.png" alt=""
          /></router-link>
        </div>
      </div>
      <div class="list" @click="createChannel('seller')">
        <div class="left">판매자 채널 등록 신청하기</div>
        <div class="right">
          <router-link to=""
            ><img src="@/assets/images/sub/my_move_ico.png" alt=""
          /></router-link>
        </div>
      </div>
      <!-- <div class="list" @click="createChannel('exchange')">
        <div class="left">리뷰거래소(구매자) 채널 등록하기</div>
        <div class="right">
          <router-link to=""
            ><img src="@/assets/images/sub/my_move_ico.png" alt=""
          /></router-link>
        </div>
      </div> -->
    </div>
    <form name="form_chk" method="post" target="auth_form">
      <input type="hidden" name="m" value="checkplusService" />
      <input type="hidden" name="EncodeData" value="" />
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    myInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState("member", ["result", "msg", "sEncData"]),
    ...mapState("mypage", ["myInfno"]),
  },
  methods: {
    createChannel(ch) {
      switch (ch) {
        case "reviewer":
          // this.$router.push("/channel/reviewer_register").catch(() => {});
          this.checkAuth();
          break;
        case "seller":
          this.$router.push("/channel/seller_register").catch(() => {});
          break;
        case "exchange":
          this.$toast.default("리뷰 거래소 채널은 준비중 입니다.");
          break;
      }
    },
    async checkAuth() {
      if (this.myInfo.is_reviewer === "Y") {
        this.$toast.default("리뷰어 채널이 존재 합니다.");
        return false;
      }
      window.open(
        "/ramm_api/member/user_auth_phone",
        "popupChk",
        "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
      );
      // await this.$store.dispatch("member/userAuthPhone");
      // if (this.result) {
      //   let encodeData = this.sEncData;
      //   document.form_chk.action =
      //     "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
      //   document.form_chk.EncodeData.value = encodeData;
      //   document.form_chk.submit();
      //   window.open(
      //     "",
      //     "auth_form",
      //     "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
      //   );
      // } else {
      //   this.$toast.default(this.msg);
      // }
    },
  },
};
</script>

<style></style>
