<template>
  <div style="margin-top: 10px">
    <div class="box" :class="{ on: shop }">
      <div class="sect_tit" @click="open('shop')">
        우리 가게 현황
        <router-link to="">
          <img src="@/assets/images/sub/my_up_ico.png" alt="" v-if="shop" />
          <img src="@/assets/images/sub/my_down_ico.png" alt="" v-else />
        </router-link>
      </div>
      <div class="sect_box my_num_2">
        <div class="list" @click="moveReviewList">
          <div class="left">우리 제품 리뷰 현황</div>
          <div class="right">
            <router-link to=""
              ><img src="@/assets/images/sub/my_move_ico.png" alt=""
            /></router-link>
          </div>
        </div>
        <div class="list">
          <div class="left" @click="moveGoodsSaleList">우리 제품 판매 현황</div>
          <div class="right">
            <router-link to=""
              ><img src="@/assets/images/sub/my_move_ico.png" alt=""
            /></router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="box" :class="{ on: review }">
      <div class="sect_tit" @click="open('review')">
        리뷰거래소 프로젝트
        <router-link to="">
          <img src="@/assets/images/sub/my_up_ico.png" alt="" v-if="review" />
          <img src="@/assets/images/sub/my_down_ico.png" alt="" v-else />
        </router-link>
      </div>
      <div class="sect_box my_num_3">
        <div class="list">
          <div class="left">리뷰어 모집 중인 프로젝트</div>
          <div class="right">
            <router-link to=""
              ><img src="@/assets/images/sub/my_move_ico.png" alt=""
            /></router-link>
          </div>
        </div>
        <div class="list">
          <div class="left">리뷰어 모집 마감된 프로젝트</div>
          <div class="right">
            <router-link to=""
              ><img src="@/assets/images/sub/my_move_ico.png" alt=""
            /></router-link>
          </div>
        </div>
        <div class="list">
          <div class="left">미션이 종결된 프로젝트</div>
          <div class="right">
            <router-link to=""
              ><img src="@/assets/images/sub/my_move_ico.png" alt=""
            /></router-link>
          </div>
        </div>
      </div>
    </div> -->
    <div class="box" :class="{ on: channel }">
      <div class="sect_tit" @click="open('channel')">
        리뷰어 / 판매자 채널 등록
        <router-link to="">
          <img src="@/assets/images/sub/my_up_ico.png" alt="" v-if="channel" />
          <img src="@/assets/images/sub/my_down_ico.png" alt="" v-else />
        </router-link>
      </div>
      <MyInfoCreateChannel :myInfo="myInfo" />
    </div>
    <div class="box" :class="{ on: myshop }">
      <div class="sect_tit" @click="open('myshop')">
        내 램 가게 가기
        <router-link to="">
          <img src="@/assets/images/sub/my_up_ico.png" alt="" v-if="shop" />
          <img src="@/assets/images/sub/my_down_ico.png" alt="" v-else />
        </router-link>
      </div>
      <div class="sect_box my_num_2">
        <div
          class="list"
          @click="$router.push(`/mini_shop/${myInfo.provider_seq}`)"
        >
          <div class="left">내 램 가게(리뷰하기) 가기</div>
          <div class="right">
            <router-link to=""
              ><img src="@/assets/images/sub/my_move_ico.png" alt=""
            /></router-link>
          </div>
        </div>
        <div class="list" @click="movePage">
          <div class="left">내 램 가게(관리자 페이지) 가기</div>
          <div class="right">
            <router-link to=""
              ><img src="@/assets/images/sub/my_move_ico.png" alt=""
            /></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyInfoCreateChannel from "@/components/mypage/myinfo/MyInfoCreateChannel";
export default {
  props: {
    myInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      shop: false,
      review: false,
      channel: false,
      myshop: false,
      adminUrl: process.env.VUE_APP_ADMIN_URL,
    };
  },
  components: {
    MyInfoCreateChannel,
  },
  methods: {
    movePage() {
      window.open(this.adminUrl);
    },
    moveReviewList() {
      this.$router.push(`/review/seller_main`);
    },
    moveGoodsSaleList() {
      this.$router.push(`/mypage/my_goods_sale_list`);
    },
    open(type) {
      this.$data[type] = !this.$data[type];
    },
  },
};
</script>

<style></style>
