<template>
  <div style="margin-top:10px;'">
    <div class="box">
      <div class="sect_tit" @click="$router.push('/mypage/my_cart')">
        장바구니<router-link to=""
          ><img src="@/assets/images/sub/my_move_ico.png" alt=""
        /></router-link>
      </div>
    </div>
    <div class="box" :class="{ on: shipping }">
      <div class="sect_tit" @click="open('shipping')">
        내 거래 내역 / 배송지 관리<a href="javascript:void(0);"
          ><img
            src="@/assets/images/sub/my_up_ico.png"
            alt=""
            v-if="shipping"
          />
          <img src="@/assets/images/sub/my_down_ico.png" alt="" v-else />
        </a>
      </div>
      <div class="sect_box my_num_3" v-if="shipping">
        <div class="list" @click="$router.push('/mypage/payment_list')">
          <div class="left">내 결제 내역</div>
          <div class="right">
            <router-link to=""
              ><img src="@/assets/images/sub/my_move_ico.png" alt=""
            /></router-link>
          </div>
        </div>
        <div
          class="list"
          @click="$router.push('/mypage/shipping_address_list')"
        >
          <div class="left">배송지 관리</div>
          <div class="right">
            <router-link to=""
              ><img src="@/assets/images/sub/my_move_ico.png" alt=""
            /></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="box" :class="{ on: channel }">
      <div class="sect_tit" @click="open('channel')">
        리뷰어 / 판매자 채널 등록
        <router-link to="">
          <img src="@/assets/images/sub/my_up_ico.png" alt="" v-if="channel" />
          <img src="@/assets/images/sub/my_down_ico.png" alt="" v-else />
        </router-link>
      </div>
      <MyInfoCreateChannel :myInfo="myInfo" />
    </div>
    <div class="box">
      <div class="sect_tit" @click="$router.push('/cs/notice')">
        공지사항(고객센터)<router-link to=""
          ><img src="@/assets/images/sub/my_move_ico.png" alt=""
        /></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import MyInfoCreateChannel from "@/components/mypage/myinfo/MyInfoCreateChannel";
export default {
  props: {
    myInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      channel: false,
      shipping: false,
    };
  },
  components: {
    MyInfoCreateChannel,
  },
  methods: {
    open(type) {
      this.$data[type] = !this.$data[type];
    },
  },
};
</script>

<style></style>
