<template>
  <div style="margin-top: 10px">
    <div class="box" :class="{ on: point }">
      <div class="sect_tit" @click="open('point')">
        리뷰 포인트 관리
        <router-link to="">
          <img src="@/assets/images/sub/my_up_ico.png" alt="" v-if="point" />
          <img src="@/assets/images/sub/my_down_ico.png" alt="" v-else />
        </router-link>
      </div>
      <div class="sect_box my_num_2">
        <!-- <div class="list" @click="$router.push('/mypage/point_withdrawal')">
          <div class="left">리뷰 포인트 인출 신청하기</div>
          <div class="right">
            <router-link to=""
              ><img src="@/assets/images/sub/my_move_ico.png" alt=""
            /></router-link>
          </div>
        </div> -->
        <div class="list" @click="$router.push('/mypage/point_change')">
          <div class="left">램 포인트로 전환하기</div>
          <div class="right">
            <router-link to=""
              ><img src="@/assets/images/sub/my_move_ico.png" alt=""
            /></router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="box" :class="{ on: review_exchange }">
      <div class="sect_tit" @click="open('review_exchange')">
        내 리뷰거래소 거래 현황
        <router-link to="">
          <img
            src="@/assets/images/sub/my_up_ico.png"
            alt=""
            v-if="review_exchange"
          />
          <img src="@/assets/images/sub/my_down_ico.png" alt="" v-else />
        </router-link>
      </div>
      <div class="sect_box my_num_3">
        <div class="list">
          <div class="left">거래 중인 프로젝트</div>
          <div class="right">
            <router-link to=""
              ><img src="@/assets/images/sub/my_move_ico.png" alt=""
            /></router-link>
          </div>
        </div>
        <div class="list">
          <div class="left">계약체결/미션수행 중인 프로젝트</div>
          <div class="right">
            <router-link to=""
              ><img src="@/assets/images/sub/my_move_ico.png" alt=""
            /></router-link>
          </div>
        </div>
        <div class="list">
          <div class="left">미션완료/지불요청한 프로젝트</div>
          <div class="right">
            <router-link to=""
              ><img src="@/assets/images/sub/my_move_ico.png" alt=""
            /></router-link>
          </div>
        </div>
        <div class="list">
          <div class="left">지불완료된 프로젝트</div>
          <div class="right">
            <router-link to=""
              ><img src="@/assets/images/sub/my_move_ico.png" alt=""
            /></router-link>
          </div>
        </div>
        <div class="list">
          <div class="left">신고/분쟁 중인 프로젝트</div>
          <div class="right">
            <router-link to=""
              ><img src="@/assets/images/sub/my_move_ico.png" alt=""
            /></router-link>
          </div>
        </div>
        <div class="list">
          <div class="left">분쟁이 해결된 프로젝트</div>
          <div class="right">
            <router-link to=""
              ><img src="@/assets/images/sub/my_move_ico.png" alt=""
            /></router-link>
          </div>
        </div>
      </div>
    </div> -->
    <div class="box" :class="{ on: channel }">
      <div class="sect_tit" @click="open('channel')">
        리뷰어 / 판매자 채널 등록
        <router-link to="">
          <img src="@/assets/images/sub/my_up_ico.png" alt="" v-if="channel" />
          <img src="@/assets/images/sub/my_down_ico.png" alt="" v-else />
        </router-link>
      </div>
      <MyInfoCreateChannel :myInfo="myInfo" />
    </div>
    <div class="box">
      <div
        class="sect_tit"
        @click="$router.push('/review/review_select_category')"
      >
        리뷰하기<router-link to=""
          ><img src="@/assets/images/sub/my_move_ico.png" alt=""
        /></router-link>
      </div>
    </div>
    <div class="box">
      <div class="sect_tit" @click="viewCardReceipt">
        리뷰어 회원권 영수증<router-link to=""
          ><img src="@/assets/images/sub/my_move_ico.png" alt=""
        /></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MyInfoCreateChannel from "@/components/mypage/myinfo/MyInfoCreateChannel";
export default {
  props: {
    myInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      point: false,
      review_exchange: false,
      channel: false,
    };
  },
  computed: {
    ...mapState("mypage", ["result", "msg", "reviewerReceipt"]),
  },
  components: {
    MyInfoCreateChannel,
  },
  methods: {
    async viewCardReceipt() {
      await this.$store.dispatch("mypage/ReviewerReceipt");
      const tno = this.reviewerReceipt.pg_transaction_number;
      const authdata = this.reviewerReceipt.authdata;
      const shopid = this.reviewerReceipt.shopid;
      if (this.result) {
        showReceiptByTID(shopid, tno, authdata);
      } else {
        this.$toast.default("등록된 결제 정보가 없습니다.");
      }
    },
    open(type) {
      this.$data[type] = !this.$data[type];
    },
  },
};
</script>

<style></style>
