<template>
  <div>
    <div class="rn_my_tit_1">
      {{ myInfo.nickname }}님의 페이지<router-link
        to=""
        @click.native="checkAuth"
        ><img src="@/assets/images/sub/my_edit_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_1">
      <div class="left">
        <img :src="myImg" @error="replaceImg" alt="" />
      </div>
      <div class="right">
        <ul>
          <li @click="$router.push('/mypage/my_ramm_point')">
            내 램 포인트<router-link to=""
              ><img src="@/assets/images/sub/my_right_ico_1.png" alt=""
            /></router-link>
            <p>총 {{ won(myInfo.ramm_point) }}P</p>
          </li>
          <li @click="$router.push('/mypage/after_evaluation')">
            내가 준 리뷰 평점<router-link to=""
              ><img src="@/assets/images/sub/my_right_ico_1.png" alt=""
            /></router-link>
          </li>
          <li @click="$router.push('/mypage/before_evaluation')">
            내가 줄 리뷰 평점<router-link to=""
              ><img src="@/assets/images/sub/my_right_ico_1.png" alt=""
            /></router-link>
          </li>
          <li @click="$router.push('/mypage/comment_list')">
            내가 쓴 댓글 보기<router-link to=""
              ><img src="@/assets/images/sub/my_right_ico_1.png" alt=""
            /></router-link>
          </li>
        </ul>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
  </div>
</template>

<script>
import check from "@/mixin/checkAuth.js";
export default {
  props: {
    myInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [check],
  computed: {
    myImg() {
      return this.myInfo.member_img
        ? `${process.env.VUE_APP_API_URL}${this.myInfo.member_img}`
        : "";
    },
  },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    won(str) {
      return this.$won(str);
    },
  },
};
</script>

<style></style>
