export default {
  methods: {
    checkAuth() {
      if (this.$store.state.mypage.checkAuth) {
        this.$router.push("/mypage/myInfo_setting");
        return false;
      } else {
        this.$router.push("/mypage/auth_check");
        return false;
      }
    },
  },
};
