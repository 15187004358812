<template>
  <div class="rn_my_wrap">
    <HeaderNormal v-if="channel.normal" :myInfo="myInfo" />
    <HeaderReviewer v-if="channel.reviewer" :myInfo="myInfo" />
    <HeaderSeller v-if="channel.seller" :myInfo="myInfo" />
    <div class="rn_my_box_2">
      <div class="box" :class="{ on: transfer }">
        <div class="sect_tit" @click="open('transfer')">
          채널전환<a href="javascript:void(0);">
            <img
              src="@/assets/images/sub/my_up_ico.png"
              alt=""
              v-if="transfer"
            />
            <img src="@/assets/images/sub/my_down_ico.png" alt="" v-else />
          </a>
        </div>
        <div class="sect_box my_num_1" v-if="transfer">
          <div class="list">
            <div class="left" :class="{ on: channel.normal }">
              일반회원 채널
            </div>
            <div
              class="right"
              @click="channelChange('normal')"
              :class="{ on: channel.normal }"
            >
              {{ myInfo.nickname }}
            </div>
            <div class="channel" :class="{ on: channel.normal }"></div>
          </div>
          <div class="list">
            <div class="left" :class="{ on: channel.reviewer }">
              리뷰어 채널
            </div>
            <div
              v-if="myInfo.is_reviewer === 'Y'"
              class="right"
              @click="channelChange('reviewer')"
              :class="{ on: channel.reviewer }"
            >
              {{ myInfo.reviewer_nick }}
            </div>
            <div class="channel" :class="{ on: channel.reviewer }"></div>
          </div>
          <div class="list">
            <div class="left" :class="{ on: channel.seller }">판매자 채널</div>
            <div
              v-if="myInfo.is_seller === 'Y'"
              class="right"
              @click="channelChange('seller')"
              :class="{ on: channel.seller }"
            >
              {{ myInfo.seller_nick }}
            </div>
            <div class="channel" :class="{ on: channel.seller }"></div>
          </div>
          <!-- <div class="list">
            <div class="left">리뷰거래소 채널</div>
            <div class="right" @click="channelChange('exchange')">
              {{ myInfo.exchange_nick }}
            </div>
            <div class="channel" :class="{ on: channel.exchange }"></div>
          </div> -->
        </div>
      </div>
      <Normal v-if="channel.normal" :myInfo="myInfo" />
      <Reviewer v-if="channel.reviewer" :myInfo="myInfo" />
      <Seller v-if="channel.seller" :myInfo="myInfo" />
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_btn black">
      <div>
        <router-link to="" @click.native="logOut">로그아웃</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HeaderNormal from "@/components/mypage/myinfo/MyInfoHeaderNormal";
import HeaderReviewer from "@/components/mypage/myinfo/MyInfoHeaderReviewer";
import HeaderSeller from "@/components/mypage/myinfo/MyInfoHeaderSeller";
import Normal from "@/components/mypage/myinfo/MyInfoNormal";
import Reviewer from "@/components/mypage/myinfo/MyInfoReviewer";
import Seller from "@/components/mypage/myinfo/MyInfoSeller";
export default {
  data() {
    return {
      transfer: true,
    };
  },
  components: {
    HeaderNormal,
    HeaderReviewer,
    HeaderSeller,
    Normal,
    Reviewer,
    Seller,
  },
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    this.getMypageMyinfo();
  },
  computed: {
    ...mapState("mypage", ["result", "msg", "myInfo", "channel", "checkAuth"]),
  },
  methods: {
    async checkChannel() {
      if (this.channel.reviewer) {
        if (!this.myInfo.is_reviewer || this.myInfo.is_reviewer === "N") {
          await this.$store.dispatch("mypage/channelChange", {
            channel: "normal",
          });
        }
      } else if (this.channel.seller) {
        if (!this.myInfo.is_seller || this.myInfo.is_seller === "N") {
          await this.$store.dispatch("mypage/channelChange", {
            channel: "normal",
          });
        }
      } else if (this.channel.exchange) {
        if (!this.myInfo.is_exchange || this.myInfo.is_exchange === "N") {
          await this.$store.dispatch("mypage/channelChange", {
            channel: "normal",
          });
        }
      }
    },
    logOut() {
      this.$store.dispatch("member/logOut");
      this.$toast.default("로그아웃 되었습니다.");
      if (this.$route.path !== "/") this.$router.push("/").catch(() => {});
      //앱에 로그아웃됨을 알려줌
      setLogOutSuccess();
    },
    open(type) {
      this.$data[type] = !this.$data[type];
    },
    async getMypageMyinfo() {
      await this.$store.dispatch("mypage/getMypageMyinfo");
      if (!this.myInfo.member_seq && !this.myInfo.userid) {
        this.logOut();
      }
      this.checkChannel();
    },
    channelChange(channel = null) {
      let msg = "";
      switch (channel) {
        case "normal":
          msg = "일반 채널로 변경하시겠습니까?";
          break;
        case "reviewer":
          msg = "리뷰어 채널로 변경하시겠습니까?";
          break;
        case "seller":
          msg = "판매자 채널로 변경하시겠습니까?";
          break;
        case "exchange":
          msg = "리뷰어 채널은 준비중 입니다.";
          this.$toast.default(msg);
          return false;
      }
      this.$confirm(msg)
        .then(async () => {
          await this.$store.dispatch("mypage/channelChange", {
            channel,
          });
          if (!this.result) {
            this.$toast.default(this.msg);
          }
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.rn_my_box_2 .box .sect_box.my_num_1 .list {
  .right {
    width: calc(100% - 150px) !important;
    &.on {
      font-weight: 600;
      color: #000;
    }
  }
  .left {
    &.on {
      font-weight: 600;
      color: #000;
    }
  }
  .channel {
    &.on {
      float: right;
      margin: 0px;
      width: 13px;
      height: 13px;
      background-color: $primary-color;
      border-radius: 50%;
    }
  }
}
</style>
